import { Button } from "@/components/ui/button";
import { CardTitle } from "@/components/ui/card";
import { useAnalytics } from "@/context/analytics-context";
import { X } from "lucide-react";
import React from "react";

interface WidgetHeaderProps {
  logoUrl: string;
  onClose: () => void;
  onFilterUpdate: (isOpen: boolean) => void;
  filterBy: string | null;
  clearFilter: () => void;
}

export const WidgetHeader: React.FC<WidgetHeaderProps> = ({
  logoUrl,
  onClose,
  onFilterUpdate,
  filterBy,
  clearFilter,
}) => {
  const { trackEvent } = useAnalytics();

  return (
    <CardTitle className="flex justify-between items-center">
      <div className="h-12 w-12 rounded-full bg-gray-200">
        <img
          src={logoUrl}
          alt="Company logo"
          className="h-12 w-auto rounded-full object-cover"
        />
      </div>
      <div className="flex-1 flex justify-center items-center gap-6">
        {filterBy ? (
          <button
            onClick={clearFilter}
            className="inline-flex items-center gap-3 px-2 py-1.5 rounded-full text-[15px] transition-all duration-200 border border-white/70 bg-transparent text-white/90 hover:bg-white/10"
          >
            <X size={20} className="text-white" />
            {filterBy}
          </button>
        ) : (
          <>
            <button
              onClick={() => onFilterUpdate(false)}
              className="relative text-[17px] font-medium text-white/90 hover:text-white transition-colors"
            >
              For you
              <div className="absolute -bottom-1 left-0 right-0 h-0.5 bg-white rounded-full" />
            </button>
            <button
              onClick={() => {
                trackEvent("sdk_discover_click");
                onFilterUpdate(true);
              }}
              className="relative text-[17px] font-medium text-white/90 hover:text-white transition-colors"
            >
              Discover
            </button>
          </>
        )}
      </div>
      <Button
        size="icon"
        onClick={onClose}
        className="w-10 h-10 rounded-full opacity-50 hover:opacity-100"
      >
        <X className="h-8 w-8" />
        <span className="sr-only">Close</span>
      </Button>
    </CardTitle>
  );
};
