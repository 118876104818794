export const API_URL = "https://teamme.link/api";

export interface Job {
  location: string;
  applyUrl: string;
  title: string;
}

export interface Location {
  name: string;
}

export const DEFAULT_PRIMARY_COLOR = "#ffffff";
export const DEFAULT_TEXT_COLOR = "#000000";
