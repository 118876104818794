import { Button } from "@/components/ui/button";
import { useAnalytics } from "@/context/analytics-context";
import { useWatchTime } from "@/hooks/useWatchTIme";
import { VideosData } from "@/types";
import { ChevronDown, ChevronUp, Volume2, VolumeX } from "lucide-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { PlayIcon } from "./play-icon";

function VideoPost({
  videoUrl,
  id,
  isMuted,
}: {
  videoUrl: string;
  id: number;
  isMuted: boolean;
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (!videoRef.current) return;

    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            videoRef.current?.play();
            setIsPlaying(true);
          } else {
            videoRef.current?.pause();
            setIsPlaying(false);
          }
        });
      },
      { threshold: 0.6 }
    );

    observerRef.current.observe(videoRef.current);

    return () => {
      observerRef.current?.disconnect();
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const updateProgress = () => {
      const progress = (video.currentTime / video.duration) * 100;
      setProgress(progress);
    };

    video.addEventListener("timeupdate", updateProgress);

    return () => {
      video.removeEventListener("timeupdate", updateProgress);
    };
  }, []);

  return (
    <div
      id={`video-${id}`}
      className="relative w-full h-full bg-black flex items-center justify-center"
    >
      <div className="absolute top-0 left-0 right-0 h-1 bg-white/30 z-10">
        <div
          className="h-full bg-white transition-all duration-300 ease-linear rounded-full"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <video
        className="w-full h-full object-cover pb-20"
        ref={videoRef}
        src={videoUrl}
        loop
        autoPlay={id === 0}
        playsInline
        muted={isMuted}
        onLoadStart={() => setIsLoading(true)}
        onLoadedData={() => setIsLoading(false)}
      />

      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
        </div>
      )}

      {!isPlaying && !isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <PlayIcon className="text-white w-12 h-12" />
        </div>
      )}
    </div>
  );
}

const debouncedScroll = (func: () => void, delay: number) => {
  let timeout: NodeJS.Timeout;

  return () => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };
};

export function VideoPlayer({
  videosData,
  shouldMute = false,
  initialMuted = false,
  isFilterMenuOpen = false,
  activeIndex = 0,
  setActiveIndex,
}: {
  videosData: VideosData[];
  shouldMute?: boolean;
  initialMuted?: boolean;
  isFilterMenuOpen?: boolean;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}) {
  const { trackEvent } = useAnalytics();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isMuted, setIsMuted] = useState(initialMuted);
  const [showVolumeIcon, setShowVolumeIcon] = useState(false);

  useWatchTime();

  const videos = useMemo(
    () => videosData.map((video) => video.url),
    [videosData]
  );

  useEffect(() => {
    setActiveIndex(0);
    const video = containerRef.current?.querySelector(
      `#video-${activeIndex}`
    ) as HTMLVideoElement;
    video.scrollIntoView({ behavior: "smooth" });
  }, [videos]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = debouncedScroll(() => {
      const containerHeight = container.clientHeight;
      const scrollTop = container.scrollTop;
      const index = Math.round(scrollTop / containerHeight);
      setActiveIndex(index);
    }, 100);

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  const onPrevious = () => {
    if (activeIndex > 0) {
      const targetIndex = activeIndex - 1;
      const targetElement = containerRef.current?.querySelector(
        `#video-${targetIndex}`
      );
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
        setActiveIndex(targetIndex);
      }
    }
  };

  useEffect(() => {
    const video = containerRef.current?.querySelector(
      `#video-${activeIndex}`
    ) as HTMLVideoElement;
  }, [activeIndex]);

  const onNext = () => {
    if (activeIndex < videos.length - 1) {
      const targetIndex = activeIndex + 1;
      const targetElement = containerRef.current?.querySelector(
        `#video-${targetIndex}`
      );
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
        setActiveIndex(targetIndex);
      }
    }
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    setShowVolumeIcon(true);
    trackEvent("sdk_mute_click", {
      isMuted: !isMuted,
    });
    setTimeout(() => {
      setShowVolumeIcon(false);
    }, 3000);
  };

  return (
    <div className="w-full h-screen md:h-[80vh] md:w-[45vh] relative">
      <div
        ref={containerRef}
        className="w-full h-full overflow-y-scroll snap-y snap-mandatory scrollbar-hide scroll-smooth"
        onClick={(e) => {
          e.stopPropagation();
          toggleMute();
        }}
      >
        {!isFilterMenuOpen && (
          <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-10">
            {(isMuted || showVolumeIcon) && (
              <Button className="w-12 h-12 rounded-full opacity-80 hover:opacity-100">
                {isMuted ? (
                  <VolumeX className="w-6 h-6 text-white" />
                ) : (
                  <Volume2 className="w-6 h-6 text-white" />
                )}
              </Button>
            )}
          </div>
        )}
        {videos.map((video, index) => (
          <div key={video} className="w-full h-full snap-start snap-always">
            <VideoPost
              videoUrl={video}
              id={index}
              isMuted={isMuted || shouldMute}
            />
          </div>
        ))}
      </div>

      {!isFilterMenuOpen && (
        <div className="flex flex-col space-y-2 absolute top-1/2 -translate-y-1/2 right-2 z-10">
          <Button
            className="w-10 h-10 rounded-full opacity-50 hover:opacity-100"
            onClick={onPrevious}
            disabled={activeIndex === 0}
          >
            <ChevronUp className="h-8 w-8" />
          </Button>
          <Button
            className="w-10 h-10 rounded-full opacity-50 hover:opacity-100"
            onClick={onNext}
            disabled={activeIndex === videos.length - 1}
          >
            <ChevronDown className="h-8 w-8" />
          </Button>
        </div>
      )}
    </div>
  );
}
