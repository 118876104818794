import { useAnalytics } from "@/context/analytics-context";
import {
  Building2,
  ChevronLeft,
  Globe2,
  Languages,
  LayoutGrid,
  Play,
} from "lucide-react";
import React, { useRef, useState } from "react";

const hiddenOnMobile = "hidden md:block";

const getNavigationItems = ({
  sites,
  departments,
  tags,
  languages,
}: {
  sites: string[];
  departments: string[];
  tags: string[];
  languages: string[];
}) => [
  {
    title: "Sites",
    icon: Globe2,
    items: sites,
  },
  {
    title: "Departments",
    icon: Building2,
    items: departments,
  },
  {
    title: "Content Categories",
    icon: LayoutGrid,
    items: tags,
  },
  {
    title: "Languages",
    icon: Languages,
    items: languages,
  },
];

export function FilterNavigation({
  onFilterClick,
  onClose,
  sites,
  departments,
  tags,
  languages,
}: {
  onFilterClick: (filter: string) => void;
  onClose: () => void;
  sites: string[];
  departments: string[];
  tags: string[];
  languages: string[];
}) {
  const { trackEvent } = useAnalytics();
  const [selectedNav, setSelectedNav] = useState<string | null>("Sites");
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleNavClick = (item: string) => {
    setSelectedNav(item);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current!.offsetLeft);
    setScrollLeft(scrollContainerRef.current!.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current!.offsetLeft;
    const walk = (x - startX) * 2;
    scrollContainerRef.current!.scrollLeft = scrollLeft - walk;
  };

  return (
    <div>
      <div className="absolute inset-0 bg-black/75 backdrop-blur-md transition-opacity duration-300 z-10" />
      <div className="absolute top-0 left-0 right-0 h-[220px] bg-gradient-to-b from-black/50 via-black/30 to-transparent pointer-events-none z-10" />
      <div className="relative flex justify-between items-center py-3 px-4 z-20">
        <button
          onClick={onClose}
          className="w-8 h-8 rounded-full bg-white/10 hover:bg-white/20 transition-colors flex items-center justify-center"
        >
          <ChevronLeft size={20} className="text-white" />
        </button>
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-lg font-semibold">
          Discover
        </div>
      </div>

      <div className="relative z-20">
        <div className="relative">
          <div className="overflow-hidden -mr-2 pr-2 scrollbar-hide-container">
            <div
              ref={scrollContainerRef}
              className="flex overflow-x-scroll gap-3 py-3 px-3 scrollbar-hide"
              style={{
                WebkitOverflowScrolling: "touch",
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              onMouseMove={handleMouseMove}
            >
              {getNavigationItems({ sites, departments, tags, languages }).map(
                (category) => {
                  const Icon = category.icon;
                  return (
                    <button
                      key={category.title}
                      onClick={() => {
                        trackEvent("sdk_category_filter_click", {
                          category: category.title,
                        });

                        handleNavClick(category.title);
                      }}
                      className={`shrink-0 px-4 py-2 rounded-full text-[16px] transition-all duration-200
                        border border-white/70 bg-transparent text-white/90 hover:bg-white/10
                        ${
                          selectedNav === category.title ? "bg-white/20" : ""
                        } flex items-center gap-2`}
                    >
                      <Icon size={16} />
                      {category.title}
                    </button>
                  );
                }
              )}
              <div className="shrink-0 w-3" aria-hidden="true" />
            </div>
          </div>
          <div
            className={`absolute left-0 top-0 bottom-0 flex items-center ${hiddenOnMobile} -z-10`}
          >
            <div className="relative w-16 h-full">
              <div
                className="absolute inset-0 bg-gradient-to-r from-black via-black to-transparent"
                style={{
                  maskImage:
                    "radial-gradient(closest-side at left center, black 70%, transparent), linear-gradient(to right, black, transparent)",
                  WebkitMaskImage:
                    "radial-gradient(closest-side at left center, black 70%, transparent), linear-gradient(to right, black, transparent)",
                  maskComposite: "intersect",
                  WebkitMaskComposite: "source-in",
                }}
              />
              <div
                className="absolute inset-0 bg-gradient-to-r from-black/90 via-black/85 to-transparent"
                style={{
                  maskImage:
                    "radial-gradient(closest-side at left center, black 60%, transparent), linear-gradient(to right, black, transparent)",
                  WebkitMaskImage:
                    "radial-gradient(closest-side at left center, black 60%, transparent), linear-gradient(to right, black, transparent)",
                  maskComposite: "intersect",
                  WebkitMaskComposite: "source-in",
                }}
              />
            </div>
          </div>
          <div
            className={`absolute right-0 top-0 bottom-0 flex items-center ${hiddenOnMobile} -z-10`}
          >
            <div className="relative w-16 h-full">
              <div
                className="absolute inset-0 bg-gradient-to-l from-black via-black to-transparent"
                style={{
                  maskImage:
                    "radial-gradient(closest-side at right center, black 70%, transparent), linear-gradient(to left, black, transparent)",
                  WebkitMaskImage:
                    "radial-gradient(closest-side at right center, black 70%, transparent), linear-gradient(to left, black, transparent)",
                  maskComposite: "intersect",
                  WebkitMaskComposite: "source-in",
                }}
              />
              <div
                className="absolute inset-0 bg-gradient-to-l from-black/90 via-black/85 to-transparent"
                style={{
                  maskImage:
                    "radial-gradient(closest-side at right center, black 60%, transparent), linear-gradient(to left, black, transparent)",
                  WebkitMaskImage:
                    "radial-gradient(closest-side at right center, black 60%, transparent), linear-gradient(to left, black, transparent)",
                  maskComposite: "intersect",
                  WebkitMaskComposite: "source-in",
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex-1 mt-4 overflow-y-auto scrollbar-hide min-h-[200px]">
          <div className="py-2">
            {getNavigationItems({ sites, departments, tags, languages })
              .find((cat) => cat.title === selectedNav)
              ?.items.filter((i) => !!i)
              .map((item, index) => (
                <button
                  key={index}
                  onClick={() => {
                    trackEvent("sdk_filter_click", {
                      category: selectedNav,
                      filter: item,
                    });

                    onFilterClick(item);
                  }}
                  className="w-full text-left py-5 px-4 text-[17px] font-normal text-white/90 hover:text-white transition-colors flex items-center justify-between border-b border-white/10"
                >
                  {item}
                  <div className="w-8 h-8 rounded-full border border-white/70 flex items-center justify-center">
                    <Play
                      size={10}
                      className="ml-0.5 fill-white rounded-[2px]"
                    />
                  </div>
                </button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
