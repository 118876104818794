import * as React from "react";

import { Button } from "@/components/ui/button";
import { CustomSelect } from "@/components/ui/custom-select";
import {
  FloatingContainer,
  FloatingContainerHeader,
  FloatingContainerTitle,
} from "@/components/ui/floating-container";
import { useAnalytics } from "@/context/analytics-context";
import { Job } from "@/lib/const";
import { Theme } from "@/types";

export function JobListings({
  isOpen,
  onClose,
  logoUrl,
  theme,
  positions,
  referralCode,
}: {
  isOpen: boolean;
  onClose: () => void;
  logoUrl: string;
  theme: Theme;
  positions: Job[];
  referralCode: string;
}) {
  const { trackEvent } = useAnalytics();
  const [location, setLocation] = React.useState("all");
  const [department, setDepartment] = React.useState("all");
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const filteredPositions = React.useMemo(() => {
    if (location === "all") return positions;

    return positions.filter((position) => position.location === location);
  }, [location, department, positions]);

  const content = (
    <>
      <div className="flex gap-2 mb-6">
        <CustomSelect
          options={[
            { value: "all", label: "All sites" },
            ...Array.from(
              new Set(positions.map((position) => position.location))
            ).map((location) => ({
              value: location,
              label: location,
            })),
          ]}
          value={location}
          onChange={(value) => setLocation(value)}
          placeholder="All sites"
        />
        {/* <CustomSelect
          options={[
            { value: "all", label: "All departments" },
            { value: "rd", label: "R&D" },
            { value: "product", label: "Product" },
          ]}
          value={department}
          onChange={(value) => setDepartment(value)}
          placeholder="All departments"
        /> */}
      </div>
      <div className="space-y-4 overflow-y-auto h-[calc(100%-5rem)] pr-4 mr-[-0.5rem]">
        {filteredPositions.map((position, index) => (
          <div
            onClick={() => {
              trackEvent("sdk_open_position_click", {
                position: position.title,
                location: position.location,
              });
              window.open(
                `${position.applyUrl.split("?")[0]}?${referralCode}`,
                "_blank"
              );
            }}
            key={index}
            className="flex items-center justify-between p-4 rounded-lg border bg-white cursor-pointer"
          >
            <div>
              <h3 className="font-medium mb-1">{position.title}</h3>
              <div className="text-sm text-gray-500 flex items-center gap-2">
                <span>{position.location}</span>
              </div>
            </div>
            <Button
              className="shrink-0 transition-colors duration-200"
              style={{
                backgroundColor: theme.primaryColor,
                color: theme.textColor,
                border: `1px solid ${theme.textColor}`,
              }}
            >
              Apply
            </Button>
          </div>
        ))}
      </div>
    </>
  );

  return (
    <FloatingContainer
      isOpen={isOpen}
      onClose={onClose}
      className="sm:max-w-[800px] h-[80vh] flex flex-col p-8"
    >
      <FloatingContainerHeader className="mb-8">
        <div className="flex justify-center gap-2">
          {logoUrl && (
            <img
              src={logoUrl}
              alt="Company logo"
              className="h-10 w-10 mb-4"
              onError={(e) => {
                (e.target as HTMLImageElement).style.display = "none";
              }}
            />
          )}
        </div>
        <FloatingContainerTitle>
          Check out our open positions
        </FloatingContainerTitle>
      </FloatingContainerHeader>
      <div className="flex-1 overflow-hidden">{content}</div>
    </FloatingContainer>
  );
}
