import React from "react";

export function TeammeLogo({ className }: { className?: string }) {
  return (
    <svg
      width="723"
      height="242"
      viewBox="0 0 723 242"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M408.652 66.5335C411.348 52.265 422.775 42.2598 437.149 40.1959C460.189 36.8875 501.268 32.75 562.621 32.75C623.974 32.75 665.054 36.8875 688.094 40.1959C702.468 42.2598 713.895 52.265 716.591 66.5335C719.441 81.6169 722.271 104.774 722.271 137.269C722.271 169.763 719.441 192.92 716.591 208.004C713.895 222.272 702.468 232.277 688.094 234.341C665.054 237.65 623.974 241.787 562.621 241.787C501.268 241.787 460.189 237.65 437.149 234.341C422.775 232.277 411.348 222.272 408.652 208.004C405.802 192.92 402.972 169.763 402.972 137.269C402.972 104.774 405.802 81.6169 408.652 66.5335Z"
        fill="white"
      />
      <path
        d="M4.19349 69.8002H86.6828V84.9152H53.7192V180.59H37.157V84.9152H4.19349V69.8002ZM155.483 142.642C155.483 144.732 155.322 146.983 155.161 149.395H95.3445C97.4348 161.616 106.44 167.887 118.499 167.887C127.343 167.887 135.544 163.867 139.725 156.953L152.267 163.224C145.674 175.766 132.167 182.519 117.856 182.519C96.1485 182.519 79.1039 166.6 79.1039 142.802C79.1039 119.004 96.4701 103.568 117.856 103.568C139.242 103.568 155.483 119.004 155.483 142.642ZM95.5053 136.692H139.725C138.117 124.954 129.594 118.2 118.017 118.2C106.44 118.2 97.7564 124.15 95.5053 136.692ZM228.734 116.753V105.497H244.492V180.59H228.734V167.887C223.91 176.57 213.619 182.519 202.041 182.519C182.424 182.519 165.54 166.118 165.54 142.963C165.54 120.612 181.459 103.568 202.685 103.568C213.619 103.568 223.91 108.231 228.734 116.753ZM204.775 167.726C217.317 167.726 228.573 157.113 228.573 142.963C228.573 128.491 216.513 118.361 204.453 118.361C191.589 118.361 181.459 129.617 181.459 142.963C181.459 156.47 191.589 167.726 204.775 167.726ZM354.22 103.568C373.033 103.568 383.164 116.271 383.164 137.657V180.59H367.245V138.461C367.245 126.24 360.813 118.683 350.683 118.683C338.301 118.522 331.869 127.366 331.869 143.124V180.59H315.95V138.461C315.95 126.24 309.518 118.683 300.192 118.683C290.383 118.683 280.414 124.954 280.414 140.23V180.59H264.656V105.497H280.414V117.879C283.469 109.035 293.599 103.568 303.89 103.568C314.825 103.568 323.99 109.356 328.492 119.326C334.924 106.301 346.663 103.568 354.22 103.568Z"
        fill="white"
      />
      <path
        d="M531.84 103.568C550.654 103.568 560.784 116.271 560.784 137.657V180.59H544.865V138.461C544.865 126.24 538.433 118.683 528.303 118.683C515.921 118.522 509.489 127.366 509.489 143.124V180.59H493.57V138.461C493.57 126.24 487.138 118.683 477.812 118.683C468.003 118.683 458.034 124.954 458.034 140.23V180.59H442.276V105.497H458.034V117.879C461.089 109.035 471.219 103.568 481.51 103.568C492.445 103.568 501.61 109.356 506.113 119.326C512.544 106.301 524.283 103.568 531.84 103.568ZM651.466 142.642C651.466 144.732 651.305 146.983 651.145 149.395H591.328C593.418 161.616 602.423 167.887 614.483 167.887C623.327 167.887 631.527 163.867 635.708 156.953L648.25 163.224C641.658 175.766 628.151 182.519 613.84 182.519C592.132 182.519 575.087 166.6 575.087 142.802C575.087 119.004 592.453 103.568 613.84 103.568C635.226 103.568 651.466 119.004 651.466 142.642ZM591.489 136.692H635.708C634.1 124.954 625.578 118.2 614 118.2C602.423 118.2 593.74 124.15 591.489 136.692Z"
        fill="black"
      />
      <path
        d="M674.548 182.198C668.599 182.198 663.453 177.695 663.453 171.585C663.453 165.475 668.599 160.973 674.548 160.973C680.659 160.973 685.804 165.475 685.804 171.585C685.804 177.695 680.659 182.198 674.548 182.198Z"
        fill="#FFBB00"
      />
    </svg>
  );
}
