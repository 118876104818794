import { useAnalytics } from "@/context/analytics-context";
import { useEffect, useRef } from "react";

export function useWatchTime() {
  const { trackEvent } = useAnalytics();
  const watchTimeRef = useRef(0);

  useEffect(() => {
    trackEvent("sdk_view");
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      watchTimeRef.current += 1;
    }, 1000);

    return () => {
      clearInterval(intervalId);
      trackEvent("sdk_player_watched", { duration: watchTimeRef.current });
    };
  }, []);
}
