import { JobListings } from "@/components/job-listings";
import {
  FilterNavigation,
  PlayIcon,
  TeammeLogo,
  VideoPlayer,
} from "@/components/player";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { useAnalytics } from "@/context/analytics-context";
import {
  API_URL,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_COLOR,
  Job,
} from "@/lib/const";
import { LANGUAGES } from "@/lib/languages";
import { cn } from "@/lib/utils";
import { SDKData } from "@/types";
import { AnimatePresence, motion } from "framer-motion";
import { XIcon } from "lucide-react";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import FAB from "./fab";
import { WidgetHeader } from "./widget-header";

export interface WidgetProps {
  data: SDKData;
  videoId?: string;
  isOpen?: boolean;
  isMuted?: boolean;
  variant: "widget" | "story" | "lightbox";
  position?: "bottom-right" | "bottom-left";
  container?: HTMLElement;
}

const getPositions = async (id: string) => {
  const response = await fetch(`${API_URL}/projects/${id}/positions`);
  const { data } = await response.json();

  return data;
};

export const Widget: React.FC<WidgetProps> = ({
  videoId,
  isOpen: initialIsOpen = false,
  isMuted: initialIsMuted = false,
  data: {
    videos: videosData,
    departments,
    sites,
    widgetData,
    logoUrl,
    playerData,
    isRtl,
    theme = {
      primaryColor: DEFAULT_PRIMARY_COLOR,
      textColor: DEFAULT_TEXT_COLOR,
    },
    integrationId,
    id: projectId,
    referralCode,
  },
  variant,
  position = "bottom-right",
}) => {
  const { trackEvent } = useAnalytics();
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [currentIndex, setCurrentIndex] = useState(0);
  const constraintsRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterBy, setFilterBy] = useState<string | null>(null);
  const [positions, setPositions] = useState<Job[]>([]);

  const mappedVideos = React.useMemo(() => {
    return videosData.map((video) => ({
      ...video,
      language:
        LANGUAGES.find((language) => language.code === video.language)?.name ||
        "English",
    }));
  }, [videosData]);

  const videos = React.useMemo(() => {
    let filteredVideos = mappedVideos.filter((video) =>
      filterBy
        ? video.site?.includes(filterBy) ||
          video.department?.includes(filterBy) ||
          video.tags.includes(filterBy) ||
          video.language === filterBy
        : true
    );

    filteredVideos = filteredVideos.sort(() => Math.random() - 0.5);
    const video = filteredVideos.find((video) => video.id === videoId);

    if (video) {
      filteredVideos = [
        video,
        ...filteredVideos.filter((v) => v.id !== videoId),
      ];
    }

    return filteredVideos.filter(
      (video) => !video.isDraft || video.id === videoId
    );
  }, [mappedVideos, filterBy, videoId]);

  React.useEffect(() => {
    trackEvent("sdk_impression");
  }, []);

  useEffect(() => {
    const fetchPositions = async () => {
      const positions = await getPositions(projectId);
      setPositions(positions);
    };
    fetchPositions();
  }, [integrationId]);

  const renderContent = () => (
    <Card
      className={cn(
        "fixed md:relative inset-0 w-full h-full rounded-none border-none md:border md:rounded-2xl overflow-hidden",
        {
          "max-w-3xl mx-auto": variant === "lightbox",
        }
      )}
    >
      <JobListings
        positions={positions}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        logoUrl={logoUrl}
        theme={theme}
        referralCode={referralCode}
      />
      <CardHeader className="absolute top-0 left-0 right-0 bg-gradient-to-b from-black/80 to-transparent z-10 py-4 px-2">
        {!isFilterOpen && (
          <WidgetHeader
            logoUrl={logoUrl}
            onClose={() => {
              trackEvent("sdk_close_click");
              setIsOpen(false);
            }}
            onFilterUpdate={setIsFilterOpen}
            filterBy={filterBy}
            clearFilter={() => {
              setFilterBy(null);
              setIsFilterOpen(false);
              setCurrentIndex(0);
            }}
          />
        )}
      </CardHeader>
      <CardContent className="p-0 overflow-hidden h-full" ref={constraintsRef}>
        <VideoPlayer
          shouldMute={isModalOpen || isFilterOpen}
          initialMuted={initialIsMuted}
          videosData={videos}
          isFilterMenuOpen={isFilterOpen}
          activeIndex={currentIndex}
          setActiveIndex={setCurrentIndex}
        />
        {isFilterOpen && (
          <div className="absolute top-0 left-0 right-0 bottom-0">
            <FilterNavigation
              onFilterClick={(filter: string) => {
                setIsFilterOpen(!isFilterOpen);
                setCurrentIndex(0);
                setFilterBy(filter);
              }}
              onClose={() => setIsFilterOpen(false)}
              sites={sites}
              departments={departments}
              tags={Array.from(
                new Set(videosData.flatMap((video) => video.tags))
              )}
              languages={Array.from(
                new Set(
                  (videosData || [])
                    .map(
                      (video) =>
                        LANGUAGES.find(
                          (language) => language.code === video.language
                        )?.name
                    )
                    .filter((language) => language !== undefined)
                )
              )}
            />
          </div>
        )}
      </CardContent>
      {!isModalOpen && !isFilterOpen && (
        <CardFooter className="absolute bottom-0 left-1/2 -translate-x-1/2 z-10 w-full bg-gradient-to-t from-black to-transparent flex flex-col items-center justify-center md:rounded-b-2xl pb-6 pt-10">
          <div className="flex flex-col gap-2 w-full mb-2">
            <div className="flex flex-col">
              <span className="text-[15px] font-semibold text-white/95 leading-snug tracking-tight">
                {videos[currentIndex].employeeName}
              </span>
              <span className="text-xs text-primary-foreground/90 leading-snug mt-0.5">
                {videos[currentIndex].employeeRole}
              </span>
            </div>
            <p className="text-xs font-normal text-white/85 leading-normal line-clamp-2">
              {videos[currentIndex].title}
            </p>
          </div>
          <Button
            onClick={() => {
              trackEvent("sdk_cta_clicked", {
                ctaType: playerData.ctaType ?? "form",
                ...(playerData.ctaType === "link"
                  ? { ctaLink: playerData.ctaLink }
                  : {}),
              });

              if (playerData.ctaType === "link") {
                window.open(
                  playerData.ctaLink ?? "https://teamme.link/",
                  "_blank"
                );
              } else {
                setIsModalOpen(true);
              }
            }}
            className="w-full rounded-[50px] text-lg font-medium h-14"
            style={{
              backgroundColor: theme.primaryColor,
              color: theme.textColor,
            }}
          >
            {playerData.ctaTitle}
          </Button>
          <button
            className="absolute bottom-0 flex flex-row justify-center items-center w-full z-20 p-1"
            onClick={(e) => {
              e.stopPropagation();
              trackEvent("sdk_powered_by_click");
              window.open("https://teamme.io/", "_blank");
            }}
          >
            <div className="text-white z-20 font-semibold text-[6px] mr-1 uppercase">
              Powered by
            </div>
            <TeammeLogo className="w-10 h-auto mb-[2px]" />
          </button>
        </CardFooter>
      )}
    </Card>
  );

  const renderWidget = () => (
    <div
      className={`fixed ${
        position === "bottom-right" ? "right-4" : "left-4"
      } bottom-4 z-[50] font-sans`}
    >
      <AnimatePresence mode="wait">
        {isOpen ? (
          <motion.div
            key="open"
            initial={{ opacity: 0, scale: 0.3, y: 50 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.3, y: 50 }}
            transition={{ duration: 0.3 }}
            className="w-full h-full inset-0 md:w-[45vh] md:h-[80vh] md:relative md:inset-auto"
          >
            {renderContent()}
          </motion.div>
        ) : !isOpened ? (
          <motion.div
            key="closed"
            initial={{ opacity: 0, scale: 0.3 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.3 }}
            transition={{ duration: 0.3 }}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(true);
              setIsOpened(true);
            }}
            className={`relative rounded-2xl w-32 md:w-52 h-52 md:h-80 overflow-hidden ${
              isVideoLoaded ? "" : "invisible"
            }`}
          >
            <div className="relative w-full h-full cursor-pointer hover:scale-110 transition-all duration-300">
              <video
                preload="auto"
                className="w-full h-full object-cover"
                playsInline
                autoPlay
                muted
                loop
                controls={false}
                onLoadedMetadata={() => setIsVideoLoaded(true)}
              >
                <source src={videos[0].url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="absolute inset-0 flex items-center justify-center">
                <PlayIcon className="w-6 h-6 text-white" />
              </div>
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsOpened(true);
                trackEvent("sdk_minimized_click");
              }}
              className="absolute top-2 right-2 bg-black/40 backdrop-blur-sm flex items-center justify-center rounded-full p-1"
            >
              <XIcon className="w-3 h-3 text-white" />
            </button>
            <div
              className="absolute bottom-0 left-0 right-0 p-2 bg-gradient-to-t from-black to-transparent rounded-b-2xl pt-8 pointer-events-none"
              dir={isRtl ? "rtl" : "ltr"}
            >
              <p className="text-lg leading-6 md:text-xl font-semibold text-center text-white md:leading-7 mb-1">
                {widgetData.title}
              </p>
            </div>
          </motion.div>
        ) : (
          <motion.div
            key="fab"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.3 }}
            className="fixed bottom-[60px] right-4 rounded-full w-14 h-14 shadow-lg hover:shadow-xl transition-shadow duration-300"
          >
            <FAB onClick={() => setIsOpen(true)} imageUrl={logoUrl} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );

  return renderWidget();
};
